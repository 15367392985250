<template>
  <v-tabs centered v-model="tab" ref="tabs">
    <v-tabs-slider color="primary"></v-tabs-slider>
    <template v-for="(rootItem, i) in filteredItems">
      <v-tab :key="i + '-children'" v-if="hasAccess(rootItem) && rootItem.children" @click="showMenu[i] = !showMenu[i]">
        <v-menu offset-y v-model="showMenu[i]">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" @click="showMenu[i] = !showMenu[i]"
              >{{ rootItem.text }}<v-icon>mdi-menu-down</v-icon></span
            >
          </template>
          <v-list dense flat :key="refreshKey">
            <v-list-item-group active-class="">
              <v-menu v-for="(item, j) in rootItem.children" offset-x :key="j">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item
                    :key="j"
                    link
                    v-if="hasAccess(item)"
                    :to="!item.children ? item.to : null"
                    @click="
                      chooseTab(i);
                      hideAllMenus(!item.children);
                    "
                  >
                    <v-list-item-content v-bind="attrs" v-on="on" @click="hideAllMenus(!item.children)">
                      <v-list-item-title
                        dense
                        @click="
                          chooseTab(i);
                          hideAllMenus(!item.children);
                        "
                      >
                        <span>{{ item.text }}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon v-if="item.children" v-bind="attrs" v-on="on">
                      <v-icon>mdi-menu-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </template>
                <v-list v-if="item.children" dense flat>
                  <v-list-item-group active-class="">
                    <v-list-item
                      @click="hideAllMenus(true)"
                      hidelink
                      v-for="innerItem in item.children"
                      :key="innerItem.text"
                      :to="innerItem.to"
                    >
                      <v-list-item-content @click="hideAllMenus(true)">
                        <v-list-item-title dense @click="hideAllMenus(true)"
                          ><span>{{ innerItem.text }}</span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-tab>
      <v-tab
        :key="i + '-no-children'"
        v-else-if="hasAccess(rootItem)"
        :to="rootItem.to"
        @click="showMenu[i] = !showMenu[i]"
      >
        <span>{{ rootItem.text }}</span>
      </v-tab>
    </template>
  </v-tabs>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TopMenu",
  data: () => ({
    loading: true,
    tab: 1,
    refreshKey: 0,
    currentTab: 1,
    filteredItems: [],
    showMenu: [],
    items: [],
    isSelectedParticipantARequester: false, // Promotion invitation only
    isSelectedParticipantAnApprover: false // Promotion invitation only
  }),
  watch: {
    selectedParticipant() {
      this.getData();
    },
    selectedProgram() {
      this.getData();
    },
    filteredItems: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.$refs.tabs.onResize();
          setTimeout(() => {
            this.$refs.tabs.onResize();
          }, 250);
        });
      }
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    hideAllMenus(value) {
      if (value) {
        this.showMenu = this.showMenu.map(() => false);
        this.$forceUpdate();
      }
    },

    getData() {
      this.loading = true;
      return this.filterItems().finally(() => {
        this.loading = false;
      });
    },

    hasAccess(menuItem) {
      if (menuItem && menuItem.impersonation) {
        if (!this.$privilege.isImpersonating()) {
          return false;
        }
      }

      // If the user has a privilege, then return true
      if (menuItem && menuItem.privileges && menuItem.privileges.length > 0) {
        let hasAtLeastOne = false;
        for (let i = 0; i < menuItem.privileges.length; i++) {
          let privilege = menuItem.privileges[i];
          if (this.$privilege.hasPrivilege(privilege)) {
            hasAtLeastOne = true;
          }
        }
        if (!hasAtLeastOne) {
          return false;
        }
      }

      if (menuItem && menuItem.participantTypes && menuItem.participantTypes.length > 0) {
        if (
          this.selectedParticipant &&
          this.selectedParticipant.participantType &&
          menuItem.participantTypes.includes(this.selectedParticipant.participantType.participantTypeKey)
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    chooseTab(i) {
      this.tab = i;
      if (this.$refs && this.$refs.tabs) {
        this.$refs.tabs.onResize();
      }
    },
    initItems() {
      this.items = [
        {
          text: this.$i18n.translate("Home"),
          to: { name: "dashboard" }
        },
        {
          text: this.$i18n.translate("Profile"),
          to: { name: "profile" }
        },
        {
          participantTypes: ["300"],
          text: this.$i18n.translate("Earnings"),
          to: { name: "salespersonEarnings" }
        },
        {
          participantTypes: ["400"],
          text: this.$i18n.translate("Race Results"),
          to: { name: "racerResults" }
        },
        {
          participantTypes: ["300", "400"],
          text: this.$i18n.translate("Rules and FAQs"),
          to: { name: this.rulesAndFaqsName }
        },
        {
          participantTypes: ["240"],
          text: this.$i18n.translate("Dealership"),
          to: { name: "myCompanyManagement" }
        },
        {
          text: this.$i18n.translate("Reports"),
          children: [
            {
              text: this.$i18n.translate("Reports"),
              to: { name: "reports" }
            },
            {
              text: this.$i18n.translate("Report Downloads"),
              to: { name: "reportDownloads" }
            }
          ]
        },
        {
          text: "Admin",
          participantTypes: ["100", "140", "150", "160"],
          children: [
            {
              privileges: ["PARTICIPANT_READ"],
              text: this.$i18n.translate("Enrollment"),
              children: [
                {
                  privileges: ["PARTICIPANT_UPDATE"],
                  text: this.$i18n.translate("Registrations"),
                  to: { name: "registrationManagement" }
                },
                {
                  privileges: ["ORGANIZATION_READ"],
                  text: this.$i18n.translate("Organizations"),
                  to: { name: "companyManagement" }
                },
                {
                  privileges: ["PARTICIPANT_READ"],
                  text: this.$i18n.translate("Participants"),
                  to: { name: "participantManagement" }
                },
                {
                  privileges: ["PARTICIPANT_READ"],
                  text: this.$i18n.translate("Cancellations"),
                  to: { name: "cancellations" }
                },
                {
                  text: this.$i18n.translate("VIN Transfer Managment"),
                  to: { name: "vinTransferManagement" }
                }
              ]
            },
            {
              privileges: ["PROMOTION_READ"],
              text: this.$i18n.translate("Promotions"),
              children: [
                {
                  privileges: ["PRODUCT_READ"],
                  text: this.$i18n.translate("Products"),
                  to: { name: "products" }
                },
                {
                  privileges: ["PROMOTION_TYPE_READ"],
                  text: this.$i18n.translate("Promotion Types"),
                  to: { name: "promotionTypes" }
                },
                {
                  privileges: ["RULE_FORMULA_READ"],
                  text: this.$i18n.translate("Promotion Formulas"),
                  to: { name: "ruleFormulas" }
                },
                {
                  privileges: ["PROMOTION_READ"],
                  text: this.$i18n.translate("Promotions"),
                  to: { name: "promotions" }
                }
              ]
            },
            {
              privileges: ["SALE_READ"],
              text: this.$i18n.translate("Transactions"),
              children: [
                {
                  privileges: ["SALE_TYPE_READ"],
                  text: this.$i18n.translate("Sale Types"),
                  to: { name: "saleTypes" }
                },
                {
                  privileges: ["SALE_READ"],
                  text: this.$i18n.translate("Sales"),
                  to: { name: "sales" }
                },
                {
                  privileges: ["PAYOUT_READ"],
                  text: this.$i18n.translate("Payouts"),
                  to: { name: "payouts" }
                },
                {
                  privileges: ["PAYOUT_CREATE"],
                  text: this.$i18n.translate("Adjustments"),
                  to: { name: "adjustments" }
                }
              ]
            },
            {
              privileges: ["COURSE_READ"],
              text: this.$i18n.translate("Courses"),
              children: [
                {
                  privileges: ["COURSE_READ"],
                  text: this.$i18n.translate("Courses"),
                  to: { name: "courses" }
                },
                {
                  privileges: ["COURSE_GRADE_READ"],
                  text: this.$i18n.translate("Course Grades"),
                  to: { name: "courseGrades" }
                }
              ]
            },
            {
              privileges: ["APPROVAL_READ"],
              text: this.$i18n.translate("Approvals"),
              children: [
                {
                  privileges: ["APPROVAL_TYPE_READ"],
                  text: this.$i18n.translate("Approval Types"),
                  to: { name: "approvalTypes" }
                },
                {
                  privileges: ["APPROVAL_READ"],
                  text: this.$i18n.translate("Approvals"),
                  to: { name: "approvals" }
                }
              ]
            },
            {
              privileges: ["CONTENT_READ"],
              text: this.$i18n.translate("Website"),
              children: [
                {
                  privileges: ["CONTENT_READ"],
                  text: this.$i18n.translate("Content"),
                  to: { name: "contents" }
                },

                {
                  privileges: ["SURVEY_READ"],
                  text: this.$i18n.translate("Surveys"),
                  to: { name: "surveys" }
                }
              ]
            },
            {
              text: this.$i18n.translate("Order Gift Cards"),
              to: { name: "giftCards" }
            }
          ]
        }
      ];
    },
    filterItems() {
      this.initItems();
      this.filteredItems = this.items.filter(elem => this.hasAccess(elem));
      this.refreshKey++;
      return Promise.resolve(this.filteredItems);
    }
  },
  computed: {
    ...mapGetters(["breadcrumbs", "selectedParticipant", "impersonationParticipant", "selectedProgram"]),
    rulesAndFaqsName() {
      return this.$kawasaki.isRacer() ? "racerRulesAndFaqs" : "salesRulesAndFaqs";
    }
  }
};
</script>
