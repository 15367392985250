<template>
  <v-toolbar-title class="mr-4 ml-4">
    <img class="logo-custom" alt="Kawasaki" src="../assets/logo-white.png" />
    <span class="d-none d-sm-none d-md-inline">
      &nbsp; | &nbsp;
      <router-link :to="{ name: 'dashboard' }" class="program-title text-decoration-none">
        {{ currentProgram }}
      </router-link>
    </span>
  </v-toolbar-title>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SelectedProgram",
  computed: {
    ...mapGetters(["selectedProgram"]),
    currentProgram() {
      let title = "";
      if (this.$kawasaki.isRacer()) {
        title = "Kawasaki Racer Rewards";
      } else if (this.$kawasaki.isSalesperson()) {
        title = "Kawasaki Sales Rewards";
      } else if (this.selectedProgram && this.selectedProgram.name) {
        title = this.selectedProgram.name;
      }
      return title;
    }
  }
};
</script>
