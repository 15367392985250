<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on: onMenu }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn small v-on="{ ...onMenu, ...onTooltip }" :aria-label="$i18n.translate('Select Program')">
            <v-icon left>mdi-apps</v-icon>
            <span class="d-none d-sm-none d-md-flex"
              >{{ selectedParticipant ? selectedParticipant.participantType.name : "" }}
              <span v-if="$kawasaki.isRacer()"> - Racer Rewards</span>
              <span v-else-if="$kawasaki.isSalesperson()"> - Sales Rewards</span>
            </span>
            <v-icon right>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Select Program") }}</span>
      </v-tooltip>
    </template>
    <v-list dense>
      <v-list-item-group color="primary" v-model="selectedItem">
        <v-list-item two-line v-for="(participant, i) in availableParticipants" :key="i" @click="onSelect(participant)">
          <v-list-item-content>
            <v-list-item-title>
              {{ participant.participantType.name }}
              {{ participant.participantType.participantTypeKey == "300" ? " - Sales Rewards" : "" }}
              {{ participant.participantType.participantTypeKey == "400" ? " - Racer Rewards" : "" }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <span v-if="participant.organization"> of {{ participant.organization.name }} </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item two-line :to="{ name: 'racerJoin' }" v-if="!$kawasaki.hasActiveRacerParticipant()">
          <v-list-item-content>
            <v-list-item-title color="primary">
              <v-icon left>mdi-flag-checkered</v-icon>
              Join Racer Rewards
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line :to="{ name: 'salespersonJoin' }" v-if="!$kawasaki.hasActiveSalespersonParticipant()">
          <v-list-item-content>
            <v-list-item-title>
              <v-icon left>mdi-seal</v-icon>
              Join Sales Rewards
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SelectedProgramDropdown",
  data: () => ({
    selectedItem: null
  }),
  computed: {
    ...mapGetters(["availableParticipants", "selectedParticipant"]),
    hasAvailableParticipants() {
      let participants = this.availableParticipants;
      return participants && participants.length > 1;
    }
  },
  mounted() {
    this.determineSelectedItem();
  },
  watch: {
    selectedParticipant() {
      this.determineSelectedItem();
    },
    availableParticipants() {
      this.determineSelectedItem();
    }
  },
  methods: {
    determineSelectedItem() {
      if (this.availableParticipants) {
        for (let i = 0; i < this.availableParticipants.length; i++) {
          if (this.availableParticipants[i].id === this.selectedParticipant.id) {
            this.selectedItem = i;
            break;
          }
        }
      }
    },
    onSelect(participant) {
      return this.$auth.storeSelectParticipant(participant).then(() => {
        this.$router.push({ name: "dashboard" }).catch(() => {});
      });
    }
  }
};
</script>
