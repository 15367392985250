<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <span v-if="$privilege.isImpersonating()">
        <v-badge color="primary" overlap>
          <template v-slot:badge>
            <v-icon x-small>mdi-lock</v-icon>
          </template>
          <v-btn small v-bind="attrs" v-on="on" color="primary" class="ml-2">
            <v-icon left>mdi-account-circle</v-icon><span class="d-none d-sm-none d-md-flex">{{ nameOnly }}</span
            ><v-icon right>mdi-menu-down</v-icon>
            <v-icon right v-if="missingProfileInfo">mdi-account-alert</v-icon>
          </v-btn>
        </v-badge>
      </span>
      <span v-else>
        <v-btn small v-bind="attrs" v-on="on" color="primary" class="ml-2">
          <v-icon left>mdi-account-circle</v-icon><span class="d-none d-sm-none d-md-flex">{{ nameOnly }}</span
          ><v-icon right>mdi-menu-down</v-icon>
          <v-icon right v-if="missingProfileInfo">mdi-account-alert</v-icon>
        </v-btn>
      </span>
    </template>
    <v-list dense>
      <v-list-item-group color="primary">
        <v-list-item three-line disabled>
          <v-list-item-content>
            <v-list-item-title v-text="organization"></v-list-item-title>
            <v-list-item-subtitle v-text="name"></v-list-item-subtitle>
            <v-list-item-subtitle
              v-text="
                selectedParticipant && selectedParticipant.participantType
                  ? selectedParticipant.participantType.name
                  : ''
              "
            ></v-list-item-subtitle>
            <v-list-item-subtitle v-if="selectedParticipant && selectedParticipant.customFieldValue1">
              Joined on {{ selectedParticipant.customFieldValue1 | formatDateClient("MM/DD/YYYY", selectedClient) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item :to="{ name: 'profile' }">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $i18n.translate("Profile") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'login' }">
          <v-list-item-icon>
            <v-icon>mdi-login</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $i18n.translate("Logout") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider v-if="$privilege.isImpersonating()"></v-divider>
        <v-subheader v-if="$privilege.isImpersonating()">
          {{ impersonationName }}
        </v-subheader>
        <v-list-item v-if="$privilege.isImpersonating()" @click="onStopImpersonate">
          <v-list-item-icon>
            <v-icon>mdi-login</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $i18n.translate("Stop Impersonating") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "UserDropdown",
  methods: {
    onStopImpersonate() {
      return this.$auth.stopImpersonation().then(() => {
        return this.$router.push({ name: "dashboard" }).catch(() => {});
      });
    }
  },
  computed: {
    ...mapGetters(["selectedClient", "selectedParticipant", "impersonationParticipant", "missingProfileInfo"]),
    nameOnly() {
      let name = this.selectedParticipant ? this.selectedParticipant.fullName : "";
      return name;
    },
    name() {
      let name = this.selectedParticipant ? this.selectedParticipant.fullName : "";
      if (this.selectedParticipant.participantKey) {
        name += " (" + this.selectedParticipant.participantKey + ")";
      }
      return name;
    },
    organization() {
      let organization = null;
      if (this.selectedParticipant && this.selectedParticipant.organization) {
        organization = this.selectedParticipant.organization.name;
        let key = this.selectedParticipant.organization.legacyOrganizationKey
          ? this.selectedParticipant.organization.legacyOrganizationKey
          : this.selectedParticipant.organization.organizationKey;
        organization += " (" + key + ")";
      }
      return organization;
    },
    impersonationName() {
      return this.impersonationParticipant ? this.impersonationParticipant.fullName : "";
    }
  }
};
</script>
