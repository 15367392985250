<template>
  <v-app>
    <v-app-bar app dark>
      <v-btn v-if="isGappInternalUser" @click="navigateToPortal()">
        Portal <v-icon right>mdi-account-cog</v-icon>
      </v-btn>
      <SelectedProgram></SelectedProgram>

      <v-spacer></v-spacer>

      <SelectedProgramDropdown></SelectedProgramDropdown>
      <UserDropdown></UserDropdown>
      <template v-slot:extension>
        <TopMenu></TopMenu>
      </template>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main class="login-bg">
      <!-- Provides the application the proper gutter -->
      <v-container class="mb-10">
        <router-view :key="$route.fullPath"></router-view>
      </v-container>
    </v-main>
    <v-footer absolute>
      <Footer></Footer>
    </v-footer>

    <v-dialog v-model="contactUsDialog" max-width="500">
      <v-card class="elevation-12">
        <v-toolbar dark color="secondary">
          <v-toolbar-title>
            <span> Contact Us </span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-8">
          <p>Our customer care team is available between 8:30am and 5:00pm (ct) Monday through Friday to assist.</p>

          <p v-if="selectedProgram.programEmail">
            <a :href="'mailto:' + selectedProgram.programEmail">{{ selectedProgram.programEmail }}</a>
          </p>
          <p v-if="selectedProgram.programPhone">
            <a :href="'tel:' + selectedProgram.programPhone">{{ selectedProgram.programPhone }}</a>
          </p>
        </v-card-text>

        <v-card-actions align="center">
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="contactUsDialog = false">Close</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import Footer from "../components/Footer.vue";
import SelectedProgram from "../components/SelectedProgram.vue";
import SelectedProgramDropdown from "../components/SelectedProgramDropdown.vue";
import TopMenu from "../components/TopMenu.vue";
import UserDropdown from "../components/UserDropdown.vue";

export default {
  name: "LayoutAuthenticated",
  components: {
    SelectedProgram,
    SelectedProgramDropdown,
    UserDropdown,
    TopMenu,
    Footer
  },
  data: () => ({
    contactUsDialog: false
  }),
  methods: {
    navigateToPortal() {
      this.$portal.navigateProgram(this.selectedProgram.id, true);
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedProgram", "selectedCountry"]),
    isGappInternalUser() {
      return this.$privilege.isGappInternalUser();
    }
  }
};
</script>
